import React from 'react';

const Category = ({text}) =>
  <span className={text === 'Score' ? 'text-m font-bold text-gray-600' : 'text-m font-bold text-red-600'}>{text}</span>

const Total = ({stat}) =>
<span className="text-m text-gray-600 text-right block">{stat}</span>

const Misses = ({misses}) =>
<span className="text-m text-red-600 text-right block">{!misses ? 0 : new Array(misses).fill('×', 0).join(' ')}</span>

export default ({current_player, players}) => {
  if (!players.length) return null;
  const player = players[current_player];
  return (
    <div className="current-player mb-4 px-1 pb-2 border-b border-gray-300">
      <span className="block text-xl text-blue-600 text-3xl leading-tight pb-2 border-b border-gray-300 mb-2">{player.name}</span>
      <div className="current-player__stats grid grid-cols-2 gap-2">
        <div>
          <Category text={'Score'} />
        </div>
        <div>
          <Total stat={player.score} />
        </div>
      </div>
      <div className="current-player__stats grid grid-cols-2 gap-2">
        <div>
          <Category text={'Misses'} />
        </div>
        <div>
          <Misses {... player} />
        </div>
      </div>
    </div>
  )
}