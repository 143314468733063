import React from 'react';

const randomGif = () => {
  const gifs = [
    'https://media.giphy.com/media/gk97b5MPUjFlRPGhTK/giphy.gif',
    'https://media.giphy.com/media/rdma0nDFZMR32/giphy.gif',
    'https://media.giphy.com/media/CNf1RMjdbJRfy/giphy.gif',
    'https://media.giphy.com/media/l1J9yTco40EU5JzTW/giphy.gif',
    'https://media.giphy.com/media/3o6Mbolqx8Ses8KXoQ/giphy.gif'
  ]
  const index = Math.floor(Math.random() * Math.floor(5));
  return gifs[index];
}

export default ({winner, updatePlayers, updateCurrentPlayer, updateScoredPins, updateWinner}) => {
  console.log(winner);
  return (
    <>
      <img src={randomGif()} alt="You Win!" className="block w-full mb-4" />
      <span className="block text-2xl text-center text-gray-700 text-bold font-bold leading-tight mb-2">{winner.name}</span>
      <span className="block text-4xl text-center text-green-500 text-bold font-bold leading-tight mb-6">YOU WIN!!!</span>
      <button
      onClick={() => {
        updatePlayers([]);
        updateCurrentPlayer(0); 
        updateScoredPins([]);
        updateWinner(null);
      }}
      className="text-white bg-red-600 hover:text-white hover:bg-red-600 border border-red-700 rounded-md text-xl font-semibold px-4 py-2 leading-snug w-full block mt-4">Reset Game?</button>
    </>
  )
}