import React from 'react';
import playerFuncs from './../utl/players';
import {calculateScore} from './../utl/pins';

const updatePlayerScore = (scored_pins, current_player) => {
  const player = Object.assign({}, current_player);
  const score = calculateScore(scored_pins);
  if (!scored_pins.length) {
    playerFuncs.addMiss(player);
    playerFuncs.addScore(player, score);
    playerFuncs.addRound(player, score);
    return player;
  }
  playerFuncs.clearMisses(player);
  playerFuncs.addScore(player, score);
  playerFuncs.addRound(player, score);
  return player;
}

export default ({scored_pins, current_player, players, updatePlayers, updateCurrentPlayer, updateScoredPins, updateWinner}) => {
  if (!players.length) return null;
  const handleClick = () => {
    const updated_player = updatePlayerScore(scored_pins, players[current_player]);
    players[current_player] = updated_player;
    updatePlayers(players.slice());
    updateCurrentPlayer(playerFuncs.determineNextPlayer(players, current_player));
    updateScoredPins([]);
    if (updated_player.score === 50) updateWinner(updated_player);
  }
  return (
    <button
    onClick={handleClick}
    className="text-white bg-green-600 hover:text-white hover:bg-green-600 border border-green-600 rounded-sm text-xl font-semibold px-4 py-2 leading-snug w-full block mt-4">
      Submit Score
    </button>
  )
}