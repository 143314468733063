import React from 'react';
import {KubbPins} from './../components/number-kubbs';
import CurrentScore from './../components/current-score';
import LockScore from './../components/lock-score';
import {addOrRemovePin} from './../utl/pins';
import CurrentPlayer from './../components/current-player';

const updatePins = (scored_pins, updateScoredPins) => {
  return ({currentTarget}) => {
    return currentTarget.checked ? updateScoredPins(addOrRemovePin(scored_pins, currentTarget.value, true)) :
    updateScoredPins(addOrRemovePin(scored_pins, currentTarget.value, false));
  }
}

export default ({_game, _update}) => {
  return (
    <div className="game__pins">
      <CurrentPlayer
      {... _update} 
      {... _game}/>
      <KubbPins
      {... _update}
      {... _game}
      handleChange={updatePins(_game.scored_pins, _update.updateScoredPins)}
      pins={new Array(12).fill(1,0).map((num, i) => num + i)} />
      <CurrentScore
      {... _update}
      {... _game} />
      <LockScore
      {... _update}
      {... _game} />
    </div>
  );
}