export const createPlayer = (index, name) => {
  return {
    id: index,
    name: name,
    score: 0,
    abbreviation: name.charAt(0),
    misses: 0,
    rounds: [],
  }
}

export const addRound = (player, score) => {
  player.rounds.push(score);
  return player;
}

export const addScoreToPlayer = (player, added_score) => {
  const new_score = player.score + added_score;
  player.score = new_score > 50 ? player.score - 24 : new_score;
  return player;
};

export const removeScroreFromPlayer = (player, removed_score) => {
  player.score = player.score - removed_score;
  return player;
}

export const addMissToPlayer = (player) => {
  player.misses = player.misses + 1;
  return player;
}

export const clearMisses = (player) => {
  player.misses = 0;
  return Object.assign({}, player);
}

export const playerHasTooManyMisses = (player, misses_setting) => {
  return player.misses > misses_setting;
}

export const getPlayer = (players, id) => {
  return players.reduce((found_player, current_player) => {
    return found_player.id !== undefined && current_player.id === id ? current_player : found_player;
  }, {});
}

export const determineCurrentPlayerId = (players, current_player) => {
  const number_of_players = players.length;
  const next_player = current_player === number_of_players - 1 ? 0 : current_player + 1;
  return players[next_player].misses < 3 ? next_player : determineCurrentPlayerId(players, next_player);
}

export default {
  create: createPlayer,
  get: getPlayer,
  addScore: addScoreToPlayer,
  subtractScore: removeScroreFromPlayer,
  addMiss: addMissToPlayer,
  clearMisses: clearMisses,
  hasToomanyMisses: playerHasTooManyMisses,
  addRound: addRound,
  determineNextPlayer: determineCurrentPlayerId
}