import React, {useState} from 'react';
import playerFuncs from './../utl/players';

export default ({updatePlayers, players, updateCurrentPlayer, updateScoredPins, updateWinner}) => {

  const [is_valid_name, validName] = useState(null);


  const handleSubmit = e => {
    e.preventDefault();
    console.dir(e.currentTarget);
    const player_name = [].slice.call(e.currentTarget).reduce((text_el, el) => {
      return el.id === 'name' && !text_el ? el.value : text_el;
    }, null);
    if (!player_name || player_name === '') return validName('ERROR');
    const new_player = playerFuncs.create(players.length, player_name);
    players.push(new_player);
    updatePlayers(players.slice(0));
    validName(null);
    e.currentTarget.reset();
  } 

  const input_border = is_valid => is_valid === 'ERROR' ? 'border-red-500' : 'border-gray-300';

  return (
    <div className="add-player">
      <span className={`block text-xl text-blue-600 text-3xl leading-tight border-gray-300 pb-2 border-b  mb-2`}>Add a Player</span>
      <form onSubmit={handleSubmit}>
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
            Name
        </label>
        <input 
        id="name"
        name="name"
        className={`shadow appearance-none border ${input_border(is_valid_name)} rounded w-full py-2 px-3 text-gray-700 leading-tight text-xl focus:outline-none focus:shadow-outline`}
        type="text" />
        <button
        type="submit"
        className="text-white bg-green-600 hover:text-white hover:bg-green-600 border border-green-600 rounded-md text-xl font-semibold px-4 py-2 leading-snug w-full block mt-4 mb-6"
        >Add Player</button>
      </form>
    </div>
  );
}