import React from 'react';

const CurrentPlayerBubble = () =>
  <span className="inline-block bg-green-500 w-2 h-2 bord rounded-full mr-1"></span>

const ScoreBoardRow = ({id, misses, name, score, current_player}) => {
  return (
    <div className="score-board__row grid grid-cols-12 text-gray-600 border-b-solid border-b-gray-300 border-b py-2">
      <div className="flex items-center col-span-6">{current_player === id ? <CurrentPlayerBubble /> : null}{name}</div>
      <div className="flex items-center justify-center col-span-3 text-center">{misses}</div>
      <div className="flex items-center justify-center col-span-3 text-center">{score}</div>
    </div>
  )
}

export default ({players, current_player}) => {
  return (
    <div className="score-board mt-6">
      <div className="score-board__row grid grid-cols-12 text-gray-600 border-b-solid border-b-gray-300 border-b py-2">
        <div className="col-span-6 font-bold">Name</div>
        <div className="col-span-3 font-bold text-center">Misses</div>
        <div className="col-span-3 font-bold text-center">Score</div>
      </div>
      {players.map(player => <ScoreBoardRow key={`player--${player.name.replace(' ', '-')}`} current_player={current_player} {... player} />)}
    </div>
  );
}