import React, {useState} from 'react';
import playerFuncs from './../utl/players';
import Game from './../pages/game';
import Players from './../pages/players';
import Settings from './../pages/settings';
import NotFound from './../pages/not-found';
import Scores from './../components/scores';
import KillScreen from './../pages/kill-screen';
import {
  HashRouter as Router,
  Switch,
  Route,
  NavLink
} from 'react-router-dom';

const Menu = ({children}) => {
  return(
    <div className="menu fixed bottom-0 left-0 h-16 w-full bg-white flex justify-center items-center shadow-lg border-t border-gray-400">
      {children}
    </div>
  );
}

const MenuItem = ({text, icon, link}) => {
  return (
    <div className="menu-item w-1/2">
      <NavLink to={link}>
        <span className="block text-center text-2xl">{icon}</span>
        <span className="block text-center text-1xl leading-tight text-gray-700 font-bold">{text}</span>
      </NavLink>
    </div>
  )
}

export default () => {

  const [players, updatePlayers] = useState([]);
  const [current_player, updateCurrentPlayer] = useState(0);
  const [scored_pins, updateScoredPins] = useState([]);
  const [winner, updateWinner] = useState(null);

  const _game = {
    players: players,
    current_player: current_player,
    scored_pins: scored_pins,
    winner: winner,
  }

  const _update = {
    updatePlayers: updatePlayers,
    updateCurrentPlayer: updateCurrentPlayer,
    updateScoredPins: updateScoredPins,
    updateWinner: updateWinner
  }

  return(
    <div className="container shadow-lg sm mx-auto p-4 mb-4 bg-white">
      <Router>
        <Switch>
          <Route
          exact
          path="/">
            {winner ?
            <KillScreen 
            {... _update}
            {... _game} /> :
            <>
            <Game
            _update={_update}
            _game={_game} />
            <Scores 
            {... _game}
            {..._update}/>
            </> }
          </Route>
          <Route
          path="/players">
            <Players
            {... _game}
            {... _update} />
            <Scores 
            {... _game}
            {..._update}/>
          <button
          onClick={() => {
            updatePlayers([]);
            updateCurrentPlayer(0); 
            updateScoredPins([]);
            updateWinner(null);
          }}
          className="text-white bg-red-600 hover:text-white hover:bg-red-600 border border-red-700 rounded-md text-xl font-semibold px-4 py-2 leading-snug w-full block mt-12">Reset Game?</button>
          </Route>
          <Route
          path="/settings">
            <Settings />
          </Route>
          <Route
          path="*">
            <NotFound />
          </Route>
        </Switch>
        <Menu>
          <MenuItem
          text='Game'
          icon={'🎲'} 
          link={'/'} />
          <MenuItem
          text='Players'
          icon={'👨‍👨‍👧‍👧'} 
          link={'/players'} />
        </Menu>
      </Router>
    </div>
  );

}

