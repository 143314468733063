import React from 'react';
import {calculateScore} from './../utl/pins';

export default ({scored_pins}) => {
  return (
    <div className="current-score flex flex-row px-1 pb-2 border-b border-gray-300 mb-4">
      <div className="w-3/5">
        <span className="text-xl block text-gray-600">
        Turn Score
        </span>
      </div>
      <div className="w-2/5">
        <span className="text-xl block text-right text-gray-600">
          {calculateScore(scored_pins)}
        </span>
      </div>
    </div>
  );
}