import React from 'react';
import './number-kubbs.css';

export const KubbPin = ({number, handleChange, is_checked}) =>
  <div
  className={`kubb-pin text-center relative ${number ? 'w-1/4' : 'w-full'} p-1`}>
    <input
    type="checkbox"
    value={number}
    checked={is_checked}
    id={`kubb-pin--${number}`}
    name={`kubb-pin--${number}`}
    onChange={handleChange}
    className="hidden" />
    <label
    className="kubb-pin__number absolute top-1 left-1 flex items-center justify-center text-lg"
    htmlFor={`kubb-pin--${number}`}>
      {number}
    </label>
  </div>

export const KubbPins = ({pins, handleChange, scored_pins}) =>
  <div className="kubb-pins container flex flex-row flex-wrap mb-4">
    {pins.map(pin => 
      <KubbPin
      key={`pin--${pin}`}
      is_checked={scored_pins.includes(parseInt(pin))}
      handleChange={handleChange}
      number={pin} />
    )}
  </div>