export const addPin = (scored_pins, pin) => {
  scored_pins.push(parseInt(pin));
  return scored_pins.map(pin => pin);
}

export const removePin = (scored_pins, pin) => {
  return scored_pins.filter(scored_pin => scored_pin !== parseInt(pin));
}


export const addOrRemovePin = (scored_pins, pin, should_add) => {
  return should_add ? addPin(scored_pins, pin) : removePin(scored_pins, pin);
}

export const calculateScore = (scored_pins) => {
  switch (true) {
    case scored_pins.length > 1:
      return scored_pins.length;
    case !scored_pins.length:
      return 0;
    default:
      return scored_pins[0]
  }
}

export default {
  calculateScore: calculateScore,
}